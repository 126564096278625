






























import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import AccountingPointTable from "./AccountingPointTable.vue";
import XSlider from "../SimpleSlider.vue";
import AccountingPointInfoTable from "./AccountingPointInfoTable.vue";
import AccountingPointParamTable from "./AccountingPointParamTable.vue";
import AccountingPointOtherEquipmentTable from "./AccountingPointOtherEquipmentTable.vue";
import InvisibleWall from "../InvisibleWall.vue";

@Component({
  components: {
    AccountingPointTable,
    XSlider,
    AccountingPointInfoTable,
    AccountingPointParamTable,
    AccountingPointOtherEquipmentTable,
    InvisibleWall,
  },
  computed: {
    ...mapGetters({
      sortedPointsByObject: "accountingPoints/sortedPointsByObject",
      pointNumber: "accountingPoint/number",
      pointName: "accountingPoint/name",
      pointAddress: "accountingPoint/address",
    }),
  },
})
class PointTabContent extends Vue {
  showPointDetails = false;
  showSliderPreloader = false;
  showWall = false;
}

export default PointTabContent;
