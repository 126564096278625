

































import { Component, Emit, Mixins, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { AccountingPointsByObject } from "@/models/accounting-point";
import XDataTable from "../hoc/Table.vue";
import TableDropdown from "./TableDropdown.vue";
import AppApiMixin from "../mixins/AppApi.vue";

@Component({
  components: { XDataTable, TableDropdown },
  computed: { ...mapGetters({ contractType: "contract/type" }) },
})
class AccountingPointTable extends Mixins(AppApiMixin) {
  [x: string]: any;

  @Prop({ required: true }) readonly points!: AccountingPointsByObject;

  headers = [
    { text: "Номер", value: "number", width: "10%" },
    { text: "Наименование", value: "name", width: "15%" },
    { text: "Адрес", value: "address", width: "15%" },
    { text: "Тип ПУ", value: "type", width: "10%" },
    { text: "Заводской номер", value: "id", width: "15%" },
    { text: "Ценовая категория", value: "priceCategory", width: "10%" },
    { text: "Уровень напряжения", value: "voltageLevel", width: "10%" },
    { text: "Группа (Форма 46)", value: "group", width: "15%" },
  ];

  pointKeys = [
    "номер",
    "названиету",
    "адрес",
    "типсчетчика",
    "заводскойномер",
    "цкатегория",
    "уровеньнапряжения",
    "аналитика46",
  ];

  columnStates: boolean[] = [];

  public get totalUsedPointKeys(): number {
    return this.contractType === 3 ? this.pointKeys.length : 3;
  }

  public get preparedHeaders() {
    return this.headers.slice(0, this.totalUsedPointKeys);
  }

  public get dropdownItems(): string[] {
    return this.preparedHeaders.map((header) => header.text);
  }

  public get preparedPoints(): Array<string[]> {
    const points = this.points.pointList;
    const pointKeys = this.pointKeys.slice(0, this.totalUsedPointKeys);

    return points.map((point) => pointKeys.map((key) => point[key]));
  }

  public get showedHeaders() {
    return this.preparedHeaders.filter((_, i) => this.columnStates[i]);
  }

  public get showedPoints() {
    return this.preparedPoints.map((point) =>
      point.filter((_, i) => this.columnStates[i])
    );
  }

  @Emit("click")
  public emitUpdateSliderPreloaderEvt(show: boolean) {
    this.$emit("update:sliderPreloader", show);
  }

  public created() {
    this.columnStates = Array(this.totalUsedPointKeys).fill(true);
  }
}

export default AccountingPointTable;
